import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../components/ImageMeta"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-removal-middlesex-nj.json")

import "../css/pages/w4w-22.scss"

const W4W = () => {
  const images = ["OSG/DEV/osg-w4w-22-01"]
  const image = (name) =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Making College More Affordable</h1>
            <h2>We’re making college more affordable for local students.</h2>
            <p>
              Our 2022 Wisdom For Wisdom program helped to offset some of the
              costs of college by providing free wisdom teeth removal for two
              New Jersey students. After having their wisdom teeth removed, the
              students will be treated with EXPAREL<sup>®</sup>, a non-opioid,
              long-lasting treatment that will ensure a healthy recovery after
              the procedure.
            </p>
            <p>
              Jacob, 24, is a college student living in Monroe Township. In his
              application, Jacob wrote about the financial stress that has come
              with paying for his education. Jacob also mentioned that he had a
              procedure performed by Dr. Simos after he had broken his jaw.
            </p>
            <p>
              Alexander, 18, just graduated from East Brunswick High School and
              plans to attend Rowan University. In his application, Alexander
              wrote about how many unexpected costs there have been already as
              he prepares to go to college, and how our Wisdom For Wisdom
              program would help him and his family.
            </p>
            <p>
              We wish every student the best as they pursue their academic
              endeavors.
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h2>
              Follow Jacob and Alexander’s wisdom teeth removal procedures on{" "}
              <a
                href="https://www.facebook.com/NJoralsurgerygroup/?fref=ts"
                title="Visit Us on Facebook"
                target="_blank"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/oralsurgerygroup/"
                title="Visit Us on Instagram"
                target="_blank"
              >
                Instagram
              </a>
              .
            </h2>
            <br />
            <p>
              Learn more about{" "}
              <a
                href="https://oralsurgerygroup.com/procedure/wisdom-teeth-removal-new-brunswick-nj/"
                title="Learn More About Wisdom Teeth"
              >
                wisdom teeth removal
              </a>
              .
            </p>
            <br />{" "}
            {/* <p>
              <strong>How to Apply:</strong>
            </p>
            <ul>
              <li>Watch a video about wisdom teeth extraction.</li>
              <li>Take a short quiz.</li>
              <li>Submit your application.</li>
            </ul>
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.{" "}
            </h3>
            <div className="w4w-22__btn-group">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                buttonClass="w4w-22__btn"
              />
              <Button
                className="w4w-22__btn"
                buttonText="Take the Quiz"
                href="/w4w-step-1/"
              />
            </div> */}
            <div className="w4w-22__facts">
              <h3>Just the facts&hellip;</h3>
              <ul>
                <li>
                  Wisdom teeth are the last of the 32 adult permanent teeth to
                  develop and erupt.
                </li>
                <li>
                  9 out of 10 people will have at least one wisdom tooth that is
                  impacted.
                </li>
                <li>Wisdom teeth are also known as third molars.</li>
                <li>
                  Wisdom teeth often appear between the ages of 17 and 25.
                </li>
                <li>
                  Wisdom teeth growing in at the wrong angle may press against
                  other teeth, causing them to become misaligned.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
      <div className="w4w-22__slider-container">
        <ImageMeta
          cloudName="nuvolum"
          publicId={images[0]}
          width="auto"
          responsive
          responsiveUseBreakpoints="true"
        />
      </div>
    </Layout>
  )
}

export default W4W
